import React from "react";
import { Page } from "../components/Page";
import { Title } from "../components/Title";
import { Content } from "../components/Content";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const PrinciplesPage = () => (
  <Page page="Periaatteet">
    <Row>
      <Col>
        <Title>Varoittamisen periaatteet</Title>

        <Content as="ul">
          <li>
            Varoitusviesti lähetetään kaikkein vaarallisimmilla keleillä, joilla
            pystyssä pysyminen edellyttää erityistä varovaisuutta ja
            huolellisuutta tai jopa apuneuvoja.
          </li>
          <li>
            Halukkaita saatetaan tarvittaessa varoittaa myös yöllä, mutta
            varmaksi sitä ei luvata. Varoittamisen tekee päivystäjä, jonka
            yöllinen työaika on riippuvainen katujen talvihoidon
            kokonaistarpeesta.
          </li>
          <li>
            Varoitettavalla on mahdollisuus valita, ottaako hän yöllisen
            liukkauden varoitusviestin vastaan heti, klo 6, klo 7, klo 8 tai
            vasta klo 9. Viikonloppuna varoitus lähetetään tuntia myöhemmin.
          </li>
          <li>
            Mikäli vaarallisen liukas keli jatkuu seuraavalle päivälle, siitä
            varoitetaan uudestaan aamulla.
          </li>
          <li>Liukkauden päättymisestä ei ilmoiteta.</li>
        </Content>

        <Content>
          Jotta varoitukset eivät kokisi "inflaatiota", niiden lähettämisen
          tavoitteena on että yli puolet alueen asukkaista kokee kelin erittäin
          liukkaaksi.
        </Content>

        <Content>
          <b>
            Varovainen pitää siis olla, vaikka varoitusta ei olisikaan tullut.
            Palvelu ei muuta millään tavalla kaupungin tai kiinteistöjen
            liukkaudentorjuntavastuita eikä jalankulkijan omaa vastuuta.
          </b>
        </Content>
      </Col>
    </Row>
  </Page>
);

export default PrinciplesPage;
